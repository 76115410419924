<template>
  <b-modal
    id="move-acknowledge-modal"
    centered
    no-close-on-backdrop
    footer-class="flex-nowrap"
  >
    <template #modal-header>
      <h3>{{ acknowledgeTitle }}</h3>
    </template>
    <div class="show-url-icon text-center px-2">
      <b-icon icon="check-circle" variant="success" class="mt-5 mb-4"></b-icon>
      <div class="mt-2 mb-4" v-html="acknowledgeDesc"></div>
    </div>
    <template #modal-footer>
      <b-button
        variant="primary"
        class="p-3"
        block
        @click="closeAcknowledgeModal"
      >
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'ModalAcknowledge',
  props: ['pageType', 'actionType'],
  data() {
    return {
      pageTypeLocaleString: null,
    };
  },
  mounted() {
    this.$bvModal.show('move-acknowledge-modal');
    if (this.pageType) {
      this.pageTypeLocaleString = this.$t(`webmag.${this.pageType}`);
    }
  },
  computed: {
    articleOfPageType() {
      if (this.$i18n.locale === 'de-DE') {
        switch (this.pageType) {
          case 'page':
            return 'Die';
          case 'project':
            return 'Das';
          case 'folder':
            return 'Der';
          default:
            return null;
        }
      }
      return null;
    },
    acknowledgeTitle() {
      switch (this.actionType) {
        case 'moveDuplicate':
          return this.$t('modals.move.duplicateAcknowledgeTitle');
        case 'move':
          return this.$t('modals.move.acknowledgeTitle');
        case 'duplicate':
          return this.$t('modals.duplicate.acknowledgeTitle', { type: this.pageTypeLocaleString });
        case 'projectCreate':
        case 'sharedCreate':
          return this.$t('modals.createItem.project.acknowledgeTitle', { type: this.pageTypeLocaleString });
        default:
          return null;
      }
    },
    acknowledgeDesc() {
      switch (this.actionType) {
        case 'moveDuplicate':
          return this.$t('modals.move.duplicateAcknowledgeDesc',
            { type: this.pageTypeLocaleString, article: this.articleOfPageType });
        case 'move':
          return this.$t('modals.move.acknowledgeDesc',
            { type: this.pageTypeLocaleString, article: this.articleOfPageType });
        case 'duplicate':
          return this.$t('modals.duplicate.acknowledgeDesc',
            { type: this.pageTypeLocaleString, article: this.articleOfPageType });
        case 'projectCreate':
          return this.$t('modals.createItem.project.acknowledgeDesc', { type: this.pageTypeLocaleString });
        case 'sharedCreate':
          return this.$t('modals.createItem.project.acknowledgeShareDesc', { type: this.pageTypeLocaleString });
        default:
          return null;
      }
    },
  },
  methods: {
    closeAcknowledgeModal() {
      this.$bvModal.hide('move-acknowledge-modal');
      this.$emit('close-acknowledge-modal');
    },
  },
};
</script>

<style scoped lang="scss">
.show-url-icon svg {
  font-size: 80px;
}

/deep/ .disabled {
  cursor: not-allowed;
}
</style>
