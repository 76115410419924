var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "move-acknowledge-modal",
        centered: "",
        "no-close-on-backdrop": "",
        "footer-class": "flex-nowrap"
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function() {
            return [_c("h3", [_vm._v(_vm._s(_vm.acknowledgeTitle))])]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function() {
            return [
              _c(
                "b-button",
                {
                  staticClass: "p-3",
                  attrs: { variant: "primary", block: "" },
                  on: { click: _vm.closeAcknowledgeModal }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "show-url-icon text-center px-2" },
        [
          _c("b-icon", {
            staticClass: "mt-5 mb-4",
            attrs: { icon: "check-circle", variant: "success" }
          }),
          _c("div", {
            staticClass: "mt-2 mb-4",
            domProps: { innerHTML: _vm._s(_vm.acknowledgeDesc) }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }